/* eslint-disable comma-dangle */
/* eslint-disable quotes */
import {LangPackDifference, LangPackString} from './layer';
import I18n from './lib/langPack';

const zhLang = {
  _: 'langPackDifference',
  lang_code: "zh-cn",
  from_version: 0,
  version: 0,
  strings: [],
  local: true,
} as LangPackDifference.langPackDifference;

function getZhLang(): Promise<LangPackDifference.langPackDifference> {
  return Promise.all([
    import('./langCN'),
    import('./langSignCN'),
    import('./countries')
  ]).then(([localLangPack1, localLangPack2, countries]) => {
    const strings: LangPackString[] = [];

    [localLangPack1, localLangPack2].forEach((l) => {
      I18n.formatLocalStrings(l.default as any, strings);
    });
    const langPack = {
      ...zhLang,
      strings,
      countries: countries.default,
    }
    return I18n.saveLangPack(langPack);
  })
};

export default getZhLang;
